<template>
  <div v-if="page" class="entry" :data-flickity-bg-lazyload="src">
    <router-link :to="page.url"></router-link>
  </div>
</template>

<script>
import delve from 'dlv'

export default {
  name: 'GridItem',
  props: ['page'],
  computed: {
    thumbnail () {
      return this.$store.getters.getThumbnail(this.page)
    },
    src () {
      return delve(this, 'page.vimeothumbnail')
        ? delve(this, 'page.vimeothumbnail')
        : this.thumbnail && this.thumbnail.lazyurl
        ? this.$store.getters.getLazySrc(this.thumbnail.lazyurl, { width: 1500 })
        : this.thumbnail.url
    },
  }
}
</script>

<style scoped>
.entry {
  position: relative;
  background-size: cover;
  background-position: center;
  color: var(--white);
}

.entry::after {
  content: '';
  display: block;
  padding-top: 100%;
}

@media (min-width: 450px) {
  .entry::after {
    padding-top: 56%;
  }
}

@media (min-width: 768px) {
  .entry::after {
    padding-top: 45%;
  }
}

.entry > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>