<template>
  <main v-if="page">
    <div 
      v-if="pages" 
      v-masonry 
      percent-position
      column-width=".grid-sizer"
      item-selector=".entry" 
      transition-duration="0"
      class="grid" 
    >
      <div class="grid-sizer"></div>
      <GridItem v-for="subpage in pages" :key="subpage.url" :page="subpage" count="true"></GridItem>
    </div>
  </main>
</template>

<script>
import { mixin } from '/lib/content-state'
import GridItem from '/components/GridItem'

export default {
  name: 'ViewPhotos',
  components: { GridItem },
  mixins: [ mixin ]
}
</script>

<style scoped>
main {
  display: block;
  padding: 0 1rem;
  margin-top: -1rem;
}

.grid .grid-sizer,
.grid .entry {
  width: 100%;
}

@media (min-width: 400px) {
  .grid .grid-sizer,
  .grid .entry {
    width: 50%;
  }
}

@media (min-width: 768px) {
  main {
    padding: 0 2rem;
    margin-top: -2rem;
  }
}

@media (min-width: 1024px) {
  .grid .grid-sizer,
  .grid .entry {
    width: 33.33%;
  }
}

@media (min-width: 1280px) {
  .grid .grid-sizer,
  .grid .entry {
    width: 25%;
  }
}
</style>