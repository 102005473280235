import VueRouter from 'vue-router'

import Home from '/views/Home'
import Films from '/views/Films'
import Photos from '/views/Photos'
import PhotoArchive from '/views/PhotoArchive'
import Project from '/views/Project'
import Default from '/views/Default'

export function createRouter () {
  return new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'is-active',
    routes: [
      { path: '/', component: Home, meta: { footer: false, invert: true } },
      { path: '/photo', component: PhotoArchive },
      { path: '/photo/:category', component: Photos, meta: { sticky: true } },
      { path: '/photo/:category/:project', component: Project, meta: { footer: false, header: false } },
      { path: '/film', component: Films, meta: { sticky: true } },
      { path: '/film/:project', component: Project, meta: { footer: false, header: false } },
      { path: '*', component: Default, meta: { invert: true } }
    ],
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    }
  })
}