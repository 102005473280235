const getters = {}

getters.getThumbnail = state => (page = { }) => {
  var thumb = undefined
  if (page.thumbnail) {
    thumb = page.files[page.thumbnail]
  }
  if (!thumb && Array.isArray(page.files)) {
    thumb = page.files[0]
  }
  return thumb
}

/**
 * Creates a KirbyImageApi friendly image src:
 *
 * /path/file.jpg?width=200&height=200&crop=1
 *
 */
 
getters.getLazySrc = state => (url, props = { }) => {
  var params = Object.keys(props).reduce((a, b) => {
    a += a === '' ? '?' : '&'
    a += `${b}=${props[b]}`
    return a
  }, '')
  return url + params
}

export default getters