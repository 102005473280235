import Vuex from 'vuex'

import contentState from '/lib/content-state'
import contentStateGetters from '/lib/content-state-getters'
import ui from './modules/ui'

export function createStore () {
  return new Vuex.Store({
    modules: { 
      ui: ui,
      content: contentState.mod
    },
    getters: {
      ...contentStateGetters
    }
  })
}
