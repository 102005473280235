<template>
  <main v-if="page">
    <div class="logo-container"></div>
    <BackgroundImage v-bind="thumbnail"></BackgroundImage>
  </main>
</template>

<script>
import { mixin } from '/lib/content-state'
import BackgroundImage from '/components/BackgroundImage'

export default {
  name: 'ViewHome',
  components: { BackgroundImage },
  mixins: [ mixin ]
}
</script>

<style scoped>
.logo-container {
  width: 75%;
  margin-bottom: 4rem;
  background-image: url('/assets/images/logo-white.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.logo-container::after {
  content: '';
  display: block;
  padding-top: 19.5719118668%;
}

@media (min-width: 768px) {
  .logo-container {
    width: 55%;
  }
}
</style>