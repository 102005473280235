<template>
  <footer v-html="text" style="font-size:0.8em"></footer>
</template>

<script>
import { mixin } from '/lib/content-state'

export default {
  name: 'Footer',
  mixins: [ mixin ],
  computed: {
    text () {
      return this.site && this.site.footer
    }
  }
}
</script>

<style scoped>
footer {
  text-align: center;
  padding: 3rem 2rem 1.5rem 2rem;
}

@media (min-width: 768px) {
  footer {
    padding: 4rem 4rem 3rem 4rem;
  }  
}
</style>