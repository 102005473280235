<template>
  <main v-if="page">
    <div v-html="page.text"></div>
    <BackgroundImage v-bind="thumbnail"></BackgroundImage>
  </main>
</template>

<script>
import { mixin } from '/lib/content-state'
import BackgroundImage from '/components/BackgroundImage'

export default {
  name: 'ViewDefault',
  components: { BackgroundImage },
  mixins: [ mixin ]
}
</script>

<style scoped>
main >>> h2 {
  text-transform: uppercase;
}

main >>> h2:not(:first-child) {
  margin-top: 1em;
}
</style>