<template>
  <div v-if="page" :class="[{ 'no-touch': noTouch }, 'entry']" v-masonry-tile>
    <router-link :to="page.url">
      <div v-if="src" :class="[{ video, count }, 'image']" :data-count="files" v-bind:style="{ 
        paddingTop: `${this.paddingTop}%`
      }" v-lazy:background-image="src"></div>
      <div class="title">
        <div :class="{ uppercase: page.title && page.subtitle }">{{page.title}}</div>
        <div>{{page.subtitle}}</div>
      </div>
    </router-link>
  </div>
</template>

<script>
import delve from 'dlv'
import hazTouch from 'haz-touch'

export default {
  name: 'GridItem',
  props: ['page', 'video', 'count'],
  computed: {
    thumbnail () {
      return this.$store.getters.getThumbnail(this.page)
    },
    src () {
      return delve(this, 'page.vimeothumbnail')
        ? delve(this, 'page.vimeothumbnail')
        : this.thumbnail && this.thumbnail.lazyurl
        ? this.$store.getters.getLazySrc(this.thumbnail.lazyurl, { width: 800 })
        : this.thumbnail.url
    },
    files () {
      return Object.keys(delve(this, 'page.files') || []).length
    },
    noTouch () {
      return !hazTouch
    },
    paddingTop () {
      return this.video ? 56 : this.thumbnail.ratio
    }
  }
}
</script>

<style scoped>
.entry {
  padding: 1rem;
  text-align: left;
}

.image {
  background-size: cover;
  position: relative;
}

.image::after {
  content: '';
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video::after {
  background-image: url('/assets/images/playhead.svg');
  background-position: center;
  background-size: 25%;
}

.count::after {
  content: attr(data-count);
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--white);
  font: var(--medium);
}

.entry.no-touch >>> a:hover .video::after {
  display: block;
}

.entry.no-touch >>> a:hover .count::after {
  display: flex;
}

.title {
  margin-top: 1rem;
}

.uppercase {
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .entry {
    padding: 2rem;
    padding-bottom: 1rem;
  }

  .video::after {
    background-size: 16%;
  }
}
</style>