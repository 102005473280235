<template>
  <div id="app" 
    :class="[{ inverted, loading }, ' ']"
    v-bind:style="{ minHeight: `${minHeight}px` }"
  >
    <Header v-if="showHeader" :sticky="sticky" :inverted="inverted"></Header>
    <router-view></router-view>
    <Footer v-if="showFooter"></Footer>
  </div>
</template>

<script>
import delve from 'dlv'
import Header from './Header'
import Footer from './Footer'

export default {
  name: 'Layout',
  components: { Header, Footer },
  data() {
    return {
      minHeight: window.innerHeight
    }
  },
  computed: {
    showFooter() {
      return this.$route.meta.footer === false ? false : true
    },
    showHeader() {
      return this.$route.meta.header === false ? false : true
    },
    loading () {
      return this.$store.state.ui.loading
    },
    inverted () {
      return this.$route.meta.invert
    },
    sticky () {
      return this.$route.meta.sticky
    },
    site () {
      return this.$store.getters.getPage('*')
    }
  },
  methods: {
    handleResize () {
      this.minHeight = window.innerHeight
    }
  },
  created () {
    this.$store.dispatch('fetchPage', '/*')
    window.addEventListener('resize', this.handleResize)
  },
  destroyed: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  metaInfo () {
    return {
      title: delve(this.site, 'title'),
      meta: [
        { vmid: 'description', name: 'description', content: delve(this.site, 'opengraph.description') },
        { vmid: 'og:title', property: 'og:title', content: delve(this.site, 'title') },
        { vmid: 'og:description', property: 'og:description', content: delve(this.site, 'opengraph.description') },
        { vmid: 'og:image', property: 'og:image', content: delve(this.site, 'opengraph.image') },
        { vmid: 'og:url', property: 'og:url', content: window.location.href },
        { vmid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.inverted {
  color: var(--white);
}

.loading {
  cursor: wait!important;
}

.loading * {
  pointer-events: none;
}
</style>