<template>
  <div v-lazy:background-image="src"></div>
</template>

<script>
export default {
  name: 'BackgroundImage',
  props: ['url', 'lazyurl'],
  computed: {
    src() {
      return this.url
    }
  }
}
</script>

<style scoped>
div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>