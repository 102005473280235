<template>
  <header :class="{ 'is-home': isHome, inverted, sticky }">
    <router-link v-if="!isHome" to="/">
      <img v-if="inverted" src="/assets/images/logo-white.svg" class="logo">
      <img v-else src="/assets/images/logo.svg" class="logo">  
    </router-link><router-link :to="link.url"
      v-for="link in links" :key="link.url" :data-title="link.title" class="italic-h"
    >{{ link.title }}</router-link>
  </header>
</template>

<script>
import { mixin } from '/lib/content-state'

export default {
  name: 'Header',
  mixins: [ mixin ],
  props: ['sticky', 'inverted'],
  computed: {
    links () {
      if (!this.site) return [ ]
      return this.site.navigation
    },
    isHome () {
      return this.$route.path === '/'
    }
  }
}
</script>

<style scoped>
header {
  padding: 2rem 2rem 1rem 2rem;
  font: var(--medium);
  line-height: var(--lh1);
  text-align: right;
}

header.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--white);
}

header.inverted {
  background: transparent;
}

a.router-link-active {
  font-style: italic;
}

header a:first-child {
  float: left;
}

header a::after {
  content: ',\00a0';
  font-style: normal;
}

header a:first-child::after,
header a:last-child::after {
  content: '';
}

header.is-home {
  display: flex;
  justify-content: space-between;
}

header.is-home a {
  position: static;
}

header.is-home a::after {
  display: none;
}

@media (min-width: 768px) {
  header {
    padding: 2rem 4rem 1rem 4rem;
    font: var(--large);
    text-align: center;
  }

  a:first-child {
    position: absolute;
    left: 4rem;
  }

  a:last-child {
    position: absolute;
    right: 4rem;
  }

  header:not(.is-home) a:nth-child(3)::after {
    content: '';
  }
}

.logo {
  height: 0.7em;
  width: auto;
}
</style>