import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Vuex from 'vuex'
import Vue from 'vue'
import { VueMasonryPlugin } from 'vue-masonry'
import VueLazyload from 'vue-lazyload'
import { sync } from 'vuex-router-sync'

import '/index.css'
import 'ress'
import 'flickity/dist/flickity.css'
import Layout from '/components/Layout'

import contentState from '/lib/content-state'
import { API_ENDPOINT } from '/lib/constants'
import { createRouter } from '/router'
import { createStore } from '/store'

Vue.config.productionTip = !process.env.NODE_ENV

Vue.use(VueRouter)
Vue.use(VueMeta)
Vue.use(Vuex)
Vue.use(VueMasonryPlugin)
Vue.use(VueLazyload)

const router = createRouter()
const store = createStore()
sync(store, router)

Vue.mixin(contentState.api({
  $store: store,
  endpoint: API_ENDPOINT,
  loadStart: () => store.dispatch('updateUi', { loading: true }),
  loadEnd: () => {
    store.dispatch('updateUi', { loading: false })
    window.prerenderReady = true
  }
}))

const app = new Vue({
  render: h => h(Layout),
  router,
  store
}).$mount('#app')