<template>
  <div class="entry">
    <div class="entry-inner">
      <canvas v-if="image" :width="image.width" :height="image.height"></canvas>      
      <div class="image-container">
        <img v-if="src" :data-flickity-lazyload="src">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectItem',
  props: ['image'],
  computed: {
    src () {
      return this.image && this.image.lazyurl
        ? this.$store.getters.getLazySrc(this.image.lazyurl, { width: 1500 })
        : this.image.url
    }
  }
}
</script>

<style scoped>
.entry {
  height: 100%;
  max-width: 90vw;
  padding: 1rem;
  padding-bottom: 2rem;
}

.entry-inner {
  height: 100%;
  position: relative;
}

canvas {
  height: 100%;
  width: auto;
  opacity: 0;
}

.image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.4s ease;
}

img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

img[data-flickity-lazyload] {
  opacity: 0!important;
}

.entry:not(.is-selected) .image-container {
  opacity: 0.075;
  filter: grayscale(100%);
}

.entry:not(.is-selected) .image-container:hover {
  opacity: 0.15;
}

@media (min-width: 768px) {
  .entry {
    padding: 1.5rem 2rem 5.5rem 2rem;
  }
}
</style>