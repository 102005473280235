import 'whatwg-fetch'
import Vue from 'vue'
import delve from 'dlv'

let endpoint = ''

const api = (opts = {}) => {
  endpoint = opts.endpoint || '' // global

  return {
    beforeRouteEnter: handleRoute,
    beforeRouteUpdate: handleRoute
  }

  function handleRoute (to, from, next) {
    if (opts.$store.getters.getShouldLoad(to.path)) {
      if (typeof opts.loadStart === 'function') opts.loadStart()
      opts.$store.dispatch('fetchPage', to.path)
        .then(() => {
          next()
          if (typeof opts.loadEnd === 'function') opts.loadEnd()
        })
    } else {
      next()
    }
  }
}

const mod = {
  state: { },
  actions: {
    fetchPage ({ commit }, url) {
      return fetchPage(url).then((pages = { }) => {
        commit('setPages', { pages })
      })
    },
    updatePages ({ commit }, { pages }) {
      commit('setPages', { pages })
    }
  },
  mutations: {
    setPages (state, { pages }) {
      Object.keys(pages).forEach(key => {
        let entry = state[key]
        if (!entry || (entry && entry._loaded !== true)) {
          Vue.set(state, key, pages[key])
        }
      })
    },
    setPage (state, { url, page }) {
      Vue.set(state, url, page)
    }
  },
  getters: {
    getPage: state => key => {
      return state[key]
    },
    getPages: state => keys => {
      return keys && Array.isArray(keys)
        ? keys.map(key => state[key]).filter(x => x)
        : []
    },
    getShouldLoad: state => key => {
      let entry = state[key]
      if (!entry || (entry && entry._loaded !== true)) {
        return true
      }
    }
  }
}

const mixin = {
  computed: {
    site () {
      return this.$store.getters.getPage('*')
    },
    page () {
      return this.$store.getters.getPage(this.$route.path)
    },
    pages () {
      return this.$store.getters.getPages(delve(this, 'page.pages'))
    },
    thumbnail () {
      return this.$store.getters.getThumbnail(this.page)
    }
  }
}

function fetchPage (url) {
  return new Promise (function (resolve, reject) {
    fetch(endpoint + url)
      .then(resp => resp.json())
      .then(json => {
        if (json.status === 'error') resolve({ [url]: { _error: 'Could not load' } })
        else resolve(json)
      })
      .catch(reject)
  })
}

export { api, mod, mixin }
export default { api, mod, mixin }