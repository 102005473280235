<template>
  <main v-if="page">
    <div v-if="slideshow && featured" class="slider-container">
      <Flickity 
        ref="flickity" 
        v-if="featured" 
        :options="flickityOptions" 
        v-on:init="flickityInit" 
        class="slider"
        @mouseenter.native="mouseEnter" 
        @mouseleave.native="mouseLeave"
      >
        <SlideItem v-for="subpage in featured" :key="subpage.url" :page="subpage"></SlideItem>
      </Flickity>
      <div :class="[{ playhead }, 'slider-title']">
        <div class="title">{{ title }}</div>
        <div class="subtitle">{{ subtitle }}</div>
      </div>
    </div>
    <div v-if="pages" class="grid">
      <GridItem v-for="subpage in pages" :key="subpage.url" :page="subpage" video="true"></GridItem>
    </div>
  </main>
</template>

<script>
import delve from 'dlv'
import Flickity from 'vue-flickity'
import 'flickity-bg-lazyload'
import { mixin } from '/lib/content-state'
import SlideItem from '/components/SlideItem'
import GridItem from '/components/GridItem'

export default {
  name: 'ViewFilms',
  components: { Flickity, GridItem, SlideItem },
  mixins: [ mixin ],
  data() {
    return {
      flickityOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        autoPlay: 3000,
        bgLazyLoad: 1
      },
      title: '',
      subtitle: '',
      playhead: false,
      slideshow: window.innerWidth >= 768
    }
  },
  computed: {
    featured () {
      return this.$store.getters.getPages(delve(this, 'page.featured'))
    }
  },
  methods: {
    flickityInit () {
      this.setSliderTitle()
      this.$refs.flickity.on('dragStart', () => {
        this.$refs.flickity.$el.style.pointerEvents = 'none'
      })
      this.$refs.flickity.on('dragEnd', () => {
        this.$refs.flickity.$el.style.pointerEvents = 'auto'
      })
      this.$refs.flickity.on('change', this.setSliderTitle)
    },
    setSliderTitle () {
      var index = this.$refs.flickity.selectedIndex()
      var slide = this.featured[index]
      this.title = slide.title
      this.subtitle = slide.subtitle
    },
    mouseEnter () {
      this.playhead = true
    },
    mouseLeave () {
      this.playhead = false
    },
    handleResize () {
      if (window.innerWidth >= 768 && !this.slideshow) {
        this.slideshow = true
      }
      if (window.innerWidth < 768 && this.slideshow) {
        this.slideshow = false
      }
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed: function () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style scoped>
main {
  display: block;
}

.grid {
  padding: 0 1rem 1rem 1rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: -1rem;
}

.grid .entry {
  width: 100%;
}

.slider-container {
  width: 100%;
  position: relative;
  padding: 0 2rem;
}

.slider {
  width: 100%;
}

.slider .entry {
  width: 100%;
}

.slider-title {
  position: absolute;
  top: 0;
  left: 4rem;
  bottom: 0;
  right: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;
  color: var(--white);
}

.slider-title .title {
  font: var(--medium);
  text-transform: uppercase;
}

.slider-title.playhead {
  background-image: url('/assets/images/playhead.svg');
  background-position: center;
  background-size: 16%;
}

.slider-title.playhead * {
  display: none;
}

@media (min-width: 500px) {
  .grid .entry {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .grid {
    padding: 2rem;
    margin-top: 0;
  }

  .slider-container {
    padding: 0 4rem;
  }

  .slider-title .title {
    font: var(--large);
  }

  .slider-title .subtitle {
    font: var(--medium);
  }

  .slider-title.playhead {
    background-size: 8%;
  }
}

@media (min-width: 1024px) {
  .grid .entry {
    width: 33.33%;
  }
}
</style>