export default {
  state: {
    loading: true
  },
  mutations: {
    setUi (state, data = { }) {
      Object.keys(data).forEach(key => {
        state[key] = data[key]
      })
    },
  },
  actions: {
    updateUi ({ commit }, data = { }) {
      commit('setUi', data)
    }
  }
}