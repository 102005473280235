<template>
  <main v-if="page">
    <!-- <ul v-if="pages" :class="[{ active }, 'pages']">
      <li v-for="(subpage, i) in pages" :key="subpage.url">
        <router-link :to="subpage.url" :data-index="i" @mouseenter.native="mouseEnter" @mouseleave.native="mouseLeave">{{subpage.title}}</router-link>
      </li>
    </ul>
    <BackgroundImage v-if="active" v-bind="active" class="background"></BackgroundImage> -->
    Coming Soon
  </main>
</template>

<script>
import { mixin } from '/lib/content-state'
import BackgroundImage from '/components/BackgroundImage'

export default {
  name: 'ViewPhotoArchive',
  components: { BackgroundImage },
  mixins: [ mixin ],
  data () {
    return {
      active: false
    }
  },
  computed: {
    thumbnails () {
      return this.pages.map(this.$store.getters.getThumbnail)
    }
  },
  methods: {
    mouseEnter (e) {
      var index = parseInt(e.target.getAttribute('data-index'))
      this.active = this.thumbnails[index]
    },
    mouseLeave () {
      this.active = false
    }
  }
}
</script>

<style scoped>
main {
  font: var(--large);
  padding: 2rem;
}

.pages.active {
  color: var(--white);
  /*mix-blend-mode: difference;*/
}

.pages li {
  display: block;
}

.pages li:not(:last-child)::after {
  content: ', ';
}

.background {
  background-size: 75%;
}

@media (min-width: 768px) {
  main { 
    padding: 4rem; 
  }
  
  .pages li {
    display: inline;
  }

  .background {
    background-size: 55%;
  }
}
</style>