<template>
  <main v-if="page" :class="[{ invert: video }, '']">
    <nav ref="nav">
      <router-link :to="page.parent" class="close"></router-link>
    </nav>
    <div class="slider-container" :style="{ height }">
      <VimeoPlayer v-if="video" ref="player" :video-id="video" autoplay="true" class="player"></VimeoPlayer>
      <Flickity v-else-if="images" ref="flickity" :options="flickityOptions" v-on:init="flickityInit" class="slider">
        <ProjectItem v-for="(image, key) in images" :key="key" :image="image"></ProjectItem>
      </Flickity>
    </div>
    <header ref="caption" v-if="video">
      <span :class="{ uppercase: page.title && page.subtitle }">{{page.title}}</span>
      <span>{{page.subtitle}}</span>
    </header>
  </main>
</template>

<script>
import delve from 'dlv'
import Flickity from 'vue-flickity'
import { vueVimeoPlayer as VimeoPlayer } from 'vue-vimeo-player'
import { mixin } from '/lib/content-state'
import ProjectItem from '/components/ProjectItem'

export default {
  name: 'ViewProject',
  components: { Flickity, ProjectItem, VimeoPlayer },
  mixins: [ mixin ],
  data () {
    return {
      height: 'auto'
    }
  },
  computed: {
    video () {
      return delve(this, 'page.vimeo')
    },
    images () {
      return delve(this, 'page.slides')
        ? delve(this, 'page.slides').map(filename => {
          return this.page.files[filename]
        })
        : false
    },
    flickityOptions () {
      return {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: Object.keys(this.images).length > 2,
        lazyLoad: 1
      }
    }
  },
  methods: {
    flickityInit () {
      var flkty = this.$refs.flickity

      flkty.on('dragStart', () => {
        flkty.$el.style.pointerEvents = 'none'
      })

      flkty.on('dragEnd', () => {
        flkty.$el.style.pointerEvents = 'auto'
      })
      
      flkty.on('staticClick', (event, pointer, cellElement, cellIndex) => {
        if ( typeof cellIndex == 'number' ) {
          flkty.select(cellIndex)
        }
      })

      this.$nextTick(() => {
        flkty.resize()
      })
    },
    handleKeyup (e) {
      if (e.keyCode === 27) {
        this.$router.push(this.page.parent)
      }
    },
    handleResize () {
      let $nav = this.$refs.nav
      let $caption = this.$refs.caption
      let height = window.innerHeight
      
      if ($nav) height = height - $nav.offsetHeight
      if ($caption) height = height - $caption.offsetHeight

      this.height = height - 3 + 'px'
    }
  },
  mounted () {
    this.handleResize()
  },
  created () {
    window.addEventListener('keyup', this.handleKeyup)
    window.addEventListener('resize', this.handleResize)
  },
  destroyed () {
    window.removeEventListener('keyup', this.handleKeyup)
    window.removeEventListener('resize', this.handleResize)
  },
  metaInfo () {
    return {
      title: delve(this.page, 'title'),
      meta: [
        { vmid: 'og:title', property: 'og:title', content: delve(this.page, 'title') },
        { vmid: 'og:description', property: 'og:description', content: delve(this.site, 'title') },
        { vmid: 'og:image', property: 'og:image', content: delve(this.page, 'opengraph.image') }
      ]
    }
  }
}
</script>

<style scoped>
main.invert {
  background: black;
  color: white;
}

.slider-container {
  width: 100%;
  flex: 1;
  position: relative;
}

.slider {
  width: 100%;
  height: 100%;
}

.slider >>> .flickity-viewport {
  height: 100%!important;
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
}

.player >>> iframe {
  width: 100%;
  height: 100%;
}

nav {
  width: 100%;
  padding: 2rem;
  padding-bottom: 0;
  text-align: right;
}

header {
  width: 100%;
  padding: 2rem;
  padding-top: 0;
}

header span {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: inline-block;
}

.close {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url('/assets/images/close.svg');
  background-size: 100% auto;
  background-position: 0 0;
}

main.invert >>> .close {
  background-position: 0 100%;
}

.uppercase {
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .player {
    padding: 2rem 4rem;
  }
}
</style>